#tooltip{
  position: absolute;
  border-radius: 1rem;
  padding: 10px;
  background-color: #BAEDBD;
  visibility: hidden;
}
#tooltip1{
  position: absolute;
  border-radius: 1rem;
  padding: 10px;
  background-color: #BAEDBD;
  visibility: hidden;
}
.transparent-card {
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(10px) !important;
}

