.custom-popper {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-left: 950px; 
    margin-top: 180px;
    z-index: 1500; /* Ensure the popper is on top */
  }
  
  .react-datepicker {
    border: 1px solid #02834A;
    border-radius: 10px;
  }
  
  .react-datepicker__header {
    background-color: #02834A;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
  }
  
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #02834A;
  }
  
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: white;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: #02834A;
    color: white;
  }
  
  .react-datepicker__day--keyboard-selected {
    background-color: #02834A;
    color: white;
  }
  
  /* Style for grayed out days */
  .react-datepicker__day--grayed-out {
    color: lightgray;
    pointer-events: none; /* Prevent selection */
  }
  
  .react-datepicker__day--grayed-out:hover {
    background-color: transparent;
  }
  